import Select from "react-select";
import _ from "lodash";
import { Dialog, Typography } from "@mui/material";
import { DialogTitle } from "@mui/material";
import Dropzone from "react-dropzone-uploader";
import { Link } from "react-router-dom";
import Tooltip from "./tooltip";
import TimeInput from "./TimeInput";
import styles from "../../styles/demoResponsive.module.css";
import classNames from "classnames";
import CustomQuestions from "./CustomQuestions";
import ReactSwitch from "react-switch";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import ReactSelect from "react-select";

const GenerateQuestionStdHots = ({
  is_bloom,
  is_new_pricing,
  setState,
  state,
  props,
  handleSubmit,
  handleTitleChange,
  tabChanged,
  handleChange,
  redirectToPlansPage,
  handleGenerateQuestion,
  updateActivityCount,
  handlePdfDocFileStatusChange,
  getDocPdfFromEvent,
  customPageHandler,
  handleDocPage,
  handleClearCustomPages,
  handleGenerateQuestionFromPdfDoc,
  isCustomDurationHandler,
  startTimeStampHandler,
  startTypingTime,
  endTimeStampHandler,
  handleClearCustomDuration,
  handleFileStatusChange,
  getFilesFromEvent,
  onInputChange,
  handleEnter,
  getContent,
  handleGenerateQuestionForWiki,
  videoExtractButtonContent,
  placeholders,
  handleWikiTopicSelect,
  handleWikiTopicFocus,
  handleWikiTopicBlur,
}) => {
  const history = useHistory();
  const [uploadingType, setUploadingType] = useState("");
  const [videoDetails, setVideoDetails] = useState("");
  const currentPath = history.location.pathname;
  const handleContentModeChange = () => {
    localStorage.removeItem("hotsToggle");

    // Toggle between the two URLs
    const targetPath =
      currentPath === "/generate-questions"
        ? "/generate-questions-blooms"
        : "/generate-questions";

    history.push(targetPath);
  };
  const videoTypes = [
    { value: "youtube", label: "Upload from YouTube URL" },
    { value: "manual", label: "Upload Manually" },
    { value: "google-drive", label: "Upload from Google Drive" },
  ];
  const handleVideoChange = (selectedOption) => {
    setUploadingType(selectedOption.value);
    setState({
      driveVideo: null,
      isUploaded: false,
      videourl: null,
      videoFile: null,
    });
  };
  const validateExtension = (mimeType) => {
    // Validate video file extensions (e.g., mp4, mov)
    const allowedMimeTypes = [
      "video/mp4",
      "video/x-msvideo",
      "video/quicktime",
    ];
    return allowedMimeTypes.includes(mimeType);
  };
  function driveFunction(e) {
    let url = e.target.value;
    setState({ driveVideo: url, isUploaded: true });
  }

  const isSwitchChecked = currentPath !== "/generate-questions";
  return (
    <div
      // style={{ minHeight: "44rem" }}
      className="question-generate-tab-outer maxw-820"
    >
      <form onSubmit={handleSubmit}>
        <div className="form-group custom-login-field">
          <div className="d-flex justify-content-between align-items-center">
            <label
              htmlFor="titleofQuestion"
              className="custom-login-field--label"
            >
              Quiz Title
            </label>
            <div
              className="d-flex align-items-center"
              style={{ gap: "7px", marginBottom: "12px" }}
            >
              <label
                htmlFor="titleofQuestion"
                className="custom-login-field--label"
                style={{
                  fontSize: "13px",
                  fontWeight: "700",
                  margin: 0,
                  padding: 0,
                }}
              >
                Bloom's Taxonomy
              </label>
              <ReactSwitch
                onColor="#49c0a0"
                height={20}
                width={36}
                // checkedIcon={false}
                // uncheckedIcon={false}
                checked={isSwitchChecked}
                onChange={handleContentModeChange}
              />
            </div>
          </div>
          <div className="position-relative">
            <input
              className={classNames(
                styles["question-generate-tab-input"],
                `custom-login-field--inputs form-control px-2 ${
                  state.titleofQuestion_emsg != "" ? "is-invalid" : ""
                }`
              )}
              type="text"
              name="titleofQuestion"
              value={state.titleofQuestion}
              onChange={handleTitleChange}
              id="titleofQuestion"
              aria-describedby="textHelp"
              placeholder={placeholders.title}
              onClick={() =>
                setState({
                  activity_count: state.activity_count + 1,
                })
              }
            />
            {state.titleofQuestion_emsg && (
              <span className="text-danger">{state.titleofQuestion_emsg}</span>
            )}
          </div>
        </div>
        <div className={classNames(styles["border-zero"], "navtab--outer")}>
          <label
            htmlFor=""
            className={classNames(
              styles["question-generate-tab-label"],
              "custom-login-field--label"
            )}
          >
            Content Type
          </label>
          <ul
            className={classNames(
              styles["question-generate-nav"],
              styles["border-zero"],
              "nav nav-tabs"
            )}
            id="myTab"
            role="tablist"
          >
            <li
              className={classNames(
                styles[`question-generate-item`],
                `nav-item ${
                  state.defaultActiveTab == "custom-tab"
                    ? styles["apply-bg"]
                    : ""
                }`
              )}
              role="presentation"
              onClick={() =>
                setState({
                  defaultActiveTab: "custom-tab",
                })
              }
            >
              <a
                className={classNames(
                  styles[`question-generate-item-text`],
                  styles["border-zero"],
                  `nav-link`
                )}
                id="custom-tab"
                // data-toggle="tab"
                href="#custom"
                role="tab"
                aria-controls="custom"
                aria-selected="false"
                onClick={(e) => (
                  //trackEvent(("Type or Paste Text Tab Clicked"),
                  tabChanged(2),
                  setState({
                    activity_count: state.activity_count + 1,
                  })
                )}
              >
                Text
              </a>
            </li>
            <li
              className={classNames(
                styles[`question-generate-item`],
                `nav-item ${
                  state.defaultActiveTab == "upload-tab"
                    ? styles["apply-bg"]
                    : ""
                }`
              )}
              style={{ position: "relative" }}
              role="presentation"
              onClick={() =>
                setState({
                  defaultActiveTab: "upload-tab",
                })
              }
            >
              <a
                className={classNames(
                  styles[`question-generate-item-text`],
                  styles["border-zero"],
                  `nav-link`
                )}
                id="PDF-tab"
                // data-toggle="tab"
                href="#PDF"
                role="tab"
                aria-controls="PDF"
                aria-selected="false"
                //style={{ paddingLeft: "8px", textAlign: "left" }}
                onClick={(e) => (
                  //trackEvent(("Upload PDF/Docx Tab Clicked"),
                  tabChanged(4),
                  setState({
                    activity_count: state.activity_count + 1,
                  })
                )}
              >
                Document
                {/* {is_new_pricing === 1 && (
                  <img
                    src="img/crown.jpg"
                    className={classNames(styles["upload-crown"])}
                    alt="icon"
                    width="30"
                    height="30"
                    style={{
                      position: "absolute",
                      top: "-2px",
                      // marginLeft: "10px",
                    }}
                  />
                )} */}
              </a>
            </li>
            <li
              className={classNames(
                styles[`question-generate-item`],
                `nav-item ${
                  state.defaultActiveTab == "video-tab"
                    ? styles["apply-bg"]
                    : ""
                }`
              )}
              style={{ position: "relative" }}
              role="presentation"
              onClick={() =>
                setState({
                  defaultActiveTab: "video-tab",
                })
              }
            >
              <a
                className={classNames(
                  styles[`question-generate-item-text`],
                  styles["border-zero"],
                  `nav-link`
                )}
                id="VideoUrl-tab"
                // data-toggle="tab"
                href="#VideoUrl"
                role="tab"
                aria-controls="VideoUrl"
                aria-selected="false"
                onClick={(e) => (
                  //trackEvent(("Video Tab Clicked"),
                  tabChanged(3),
                  setState({
                    activity_count: state.activity_count + 1,
                  })
                )}
              >
                Video
                {/* {is_new_pricing === 1 && (
                  <img
                    src="img/crown.jpg"
                    className={classNames(styles["video-crown"])}
                    alt="icon"
                    width="30"
                    height="30"
                    style={{
                      position: "absolute",
                      top: "-4px",
                      marginLeft: "-7px",
                    }}
                  />
                )} */}
              </a>
            </li>
            <li
              className={classNames(
                styles[`question-generate-item`],
                `nav-item ${
                  state.defaultActiveTab == "wiki" ? styles["apply-bg"] : ""
                }`
              )}
              onClick={() =>
                setState({
                  defaultActiveTab: "wiki",
                })
              }
              role="presentation"
            >
              <a
                className={classNames(
                  styles[`question-generate-item-text`],
                  styles["border-zero"],
                  `nav-link`
                )}
                id="wiki-tab"
                // data-toggle="tab"
                href="#wiki"
                role="tab"
                aria-controls="wiki"
                aria-selected="true"
                onClick={(e) => (
                  //trackEvent(("Topic Search Tab Clicked"),
                  tabChanged(1),
                  setState({
                    activity_count: state.activity_count + 1,
                  })
                )}
              >
                Topic{" "}
              </a>
              {/* <span className="pro-tag badge">Pro</span> */}
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${
                state.defaultActiveTab == "custom-tab" ? "active show" : ""
              }`}
              id="custom"
              role="tabpanel"
              aria-labelledby="custom-tab"
            >
              <div className="form-group custom-login-field">
                <div className="position-relative" style={{ height: "400px" }}>
                  <label
                    htmlFor="contentForQuestions"
                    className={classNames(
                      styles["question-generate-tab-label"],
                      "custom-login-field--label"
                    )}
                  >
                    Type/Paste your Content here
                  </label>
                  <textarea
                    className={classNames(
                      styles["question-generate-tab-input"],
                      `custom-login-field--inputs form-control px-2 resize--none ${
                        state.showWordLimit ? "border border-danger" : ""
                      }`
                    )}
                    id="contentForQuestions"
                    name="contentForQuestions"
                    value={state.contentForQuestions}
                    onChange={handleChange}
                    aria-describedby="textareaHelp"
                    rows="9"
                    placeholder={placeholders.content}
                    onClick={() =>
                      setState({
                        activity_count: state.activity_count + 1,
                      })
                    }
                    style={{ height: "87%" }}
                  ></textarea>
                  {state.showWordLimit && (
                    <span className="word_limit">
                      Please enter at least 100 words
                    </span>
                  )}
                </div>

                {state.currentActiveTab === 2 && (
                  <CustomQuestions isblooms={is_bloom} />
                )}

                {/* Generate Questions Button */}
                <div className="btn-flex100">
                  {/* Filter Section */}
                  {/* <Filter/> */}

                  <button
                    type="button"
                    className={classNames(
                      styles["question-generate-submit"],
                      "btn--submit"
                    )}
                    onClick={
                      props.user.credits <= 1 &&
                      !props.user.plan_details.is_new_pricing &&
                      is_bloom
                        ? redirectToPlansPage
                        : handleGenerateQuestion
                    }
                    disabled={
                      state.contentForQuestions
                        ? false
                        : props?.user?.plan_details?.is_new_pricing
                        ? true
                        : false
                    }
                  >
                    {props.user.credits <= 1 &&
                    !props.user.plan_details.is_new_pricing &&
                    is_bloom
                      ? "Buy Credits"
                      : "Generate Questions"}
                  </button>
                </div>
                {/*  */}
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                state.defaultActiveTab == "upload-tab" ? "active show" : ""
              }`}
              id="PDF"
              role="tabpanel"
              aria-labelledby="PDF-tab"
            >
              <div className="position-relative" style={{ height: "400px" }}>
                <label>Upload a Document Here</label>
                <div className="video--content" style={{ height: "87%" }}>
                  <div
                    className={`m-auto text-center video--content-font ${
                      !_.isEmpty(props.user) &&
                      !props.user.plan_details.upload_pdf_doc_is_allowed
                        ? "sm-disabled"
                        : ""
                    }`}
                    style={{ width: "87%" }}
                    onClick={updateActivityCount}
                  >
                    <Dropzone
                      accept=".pdf, .PDF, .docx, .doc"
                      maxFiles={1}
                      canCancel={true}
                      data-tip
                      inputContent="Drag Files or Click to Browse(.pdf, .docx, .doc)"
                      onChangeStatus={handlePdfDocFileStatusChange}
                      getUploadParams={() => ({
                        url: "https://httpbin.org/post",
                      })}
                      // InputComponent={Input}
                      getFilesFromEvent={getDocPdfFromEvent}
                      styles={{
                        dropzone: {
                          maxHeight: 250,
                          wordBreak: "break-word",
                        },
                      }}
                      disabled={
                        !_.isEmpty(props.user) &&
                        !props.user.plan_details.upload_pdf_doc_is_allowed
                      }
                    />
                    {state.pdfDocFile && (
                      <div
                        style={{
                          display: "inline-flex",
                          gap: "10px",
                          alignItems: "center",
                          marginTop: "1rem",
                        }}
                      >
                        {!state.isCustomPages ? (
                          <div
                            style={{
                              cursor: "pointer",
                              color: "#49c0a0",
                            }}
                            onClick={customPageHandler}
                          >
                            Click here to select custom pages.
                          </div>
                        ) : (
                          <div className="get-doc-pages">
                            {/* <label htmlFor="page-no">
                      {state.docPageStatement}
                    </label> */}
                            <div className="notify-tool-tip">
                              <i
                                style={{
                                  color: "#007bff",
                                  marginTop: "0.25rem",
                                  cursor: "pointer",
                                }}
                                data-for="iota"
                                className="fas fa-info-circle"
                              ></i>
                              <span className="hoverText">
                                Type page numbers and/or page ranges, seprated
                                by commas. For example: 1,3,5-12,14.
                              </span>
                            </div>
                            <input
                              id="page-no"
                              type="text"
                              onChange={handleDocPage}
                              value={state.docPage}
                              placeholder="1,3,5-12,14"
                            />
                            {state.docPage && (
                              <span
                                style={{
                                  fontWeight: "700",
                                  cursor: "pointer",
                                }}
                                onClick={handleClearCustomPages}
                              >
                                x
                              </span>
                            )}
                          </div>
                        )}
                        {/* <span
                      style={{ height: "1.2rem" }}
                      className="badge badge-info"
                    >
                      BETA
                    </span> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Custom dropzone code goes here */}

              {state.currentActiveTab === 4 && (
                <CustomQuestions isblooms={is_bloom} />
              )}

              <div className="btn-flex100">
                {/* Filter Section */}
                {/* <Filter/> */}

                <button
                  type="submit"
                  className={classNames(
                    styles["question-generate-submit"],
                    "btn--submit"
                  )}
                  onClick={
                    props.user.credits <= 1 &&
                    !props.user.plan_details.is_new_pricing &&
                    is_bloom
                      ? redirectToPlansPage
                      : handleGenerateQuestionFromPdfDoc
                  }
                  disabled={
                    state.pdfDocFile != null
                      ? false
                      : props?.user?.plan_details?.is_new_pricing
                      ? true
                      : false
                  }
                >
                  {props.user.credits <= 1 &&
                  !props.user.plan_details.is_new_pricing &&
                  is_bloom
                    ? "Buy Credits"
                    : "Generate Questions"}
                </button>
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                state.defaultActiveTab == "video-tab" ? "active show" : ""
              }`}
              id="VideoUrl"
              role="tabpanel"
              aria-labelledby="VideoUrl-tab"
            >
              {/* Video Content Goes Here */}
              <div className="position-relative" style={{ height: "400px" }}>
                <label>Upload a Video Here</label>

                <div className="video--content" style={{ height: "87%" }}>
                  <div
                    className="m-auto text-center video--content-font"
                    style={{ width: "70%" }}
                  >
                    <label>Select the Source </label>
                    <ReactSelect
                      options={videoTypes}
                      placeholder="Select a source..."
                      onChange={handleVideoChange}
                    />
                  </div>
                  {/* <h5 className="mt-2 mb-1 text-center videoordiv">
                  <span>OR</span>
                </h5> */}
                  {uploadingType === "youtube" && (
                    <div
                      className="m-auto text-center video--content-font"
                      style={{ width: "70%" }}
                    >
                      <label htmlFor=""> Enter a Video URL </label>
                      {/* <p className="finger-pointing-down"></p> */}

                      <div className="videoImg-content">
                        <img
                          src={`${process.env.REACT_APP_PROJECT_URL}/img/videoURL.svg`}
                          alt="videoImage"
                        />
                        <input
                          style={{
                            cursor: "not-allowed",
                            opacity: "0.5",
                          }}
                          type="text"
                          className={`voideourl--text ${
                            !_.isEmpty(props.user) &&
                            !props.user.plan_details.video_is_allowed
                              ? ""
                              : ""
                          }`}
                          name="videourl"
                          data-tip
                          data-for="upload_video"
                          value={state.videourl}
                          onChange={handleChange}
                          placeholder="Paste Video URL from Youtube"
                          disabled
                          onClick={() =>
                            setState({
                              activity_count: state.activity_count + 1,
                            })
                          }
                        />
                        <Tooltip
                          id="upload_video"
                          title="You can either use YouTube URL or Upload video. This feature is currently disabled."
                        />
                      </div>

                      {state.videourl && (
                        <div
                          style={{
                            display: "inline-flex",
                            gap: "10px",
                            alignItems: "center",
                            position: "relative",
                            marginTop: "1rem",
                          }}
                        >
                          {!state.isCustomDuration ? (
                            <div
                              style={{
                                cursor: "pointer",
                                color: "#49c0a0",
                              }}
                              onClick={isCustomDurationHandler}
                            >
                              Click here to select Duration.
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <TimeInput
                                  id="startTime"
                                  timeStampHandler={(secs) =>
                                    startTimeStampHandler(secs)
                                  }
                                  startTyping={startTypingTime}
                                  isValid={state.timeInputMsg}
                                />
                                to
                                <TimeInput
                                  id="endTime"
                                  timeStampHandler={(secs) =>
                                    endTimeStampHandler(secs)
                                  }
                                  startTyping={startTypingTime}
                                  isValid={state.timeInputMsg}
                                />
                                <span
                                  style={{
                                    fontWeight: "700",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleClearCustomDuration}
                                >
                                  x
                                </span>
                              </div>
                              <div
                                style={{
                                  color: "#dc3545",
                                  fontWeight: "500",
                                  marginTop: "6px",
                                  fontSize: "13px",
                                }}
                                className="text-left"
                              >
                                {state.timeInputMsg}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {uploadingType === "manual" && (
                    <div
                      className="m-auto text-center video--content-font"
                      style={{ width: "70%" }}
                    >
                      <div className="videoImg-content">
                        <label htmlFor="" className="mt-1">
                          Upload Video
                        </label>
                        <div
                          className={`${
                            !_.isEmpty(props.user) &&
                            !props.user.plan_details.video_is_allowed
                              ? "sm-disabled"
                              : ""
                          }`}
                          onClick={updateActivityCount}
                        >
                          <Dropzone
                            name="video-dropzone"
                            accept="video/*"
                            maxFiles={1}
                            canCancel={true}
                            data-tip
                            data-for="upload_video"
                            inputContent="Drag Files or Click to Browse(.mp4, .avi, .mov, .wmv)"
                            onChangeStatus={handleFileStatusChange}
                            getUploadParams={() => ({
                              url: "https://httpbin.org/post",
                            })}
                            // InputComponent={Input}
                            getFilesFromEvent={getFilesFromEvent}
                            disabled={
                              state.videourl
                                ? true
                                : false ||
                                  (!_.isEmpty(props.user) &&
                                    !props.user.plan_details.video_is_allowed)
                            }
                            styles={{
                              dropzone: {
                                maxHeight: 250,
                                wordBreak: "break-word",
                              },
                            }}
                          />
                          {state.videoFile && (
                            <div
                              style={{
                                display: "inline-flex",
                                gap: "10px",
                                position: "relative",
                                alignItems: "center",
                                marginTop: "1rem",
                              }}
                            >
                              {!state.isCustomDuration ? (
                                <div
                                  style={{
                                    cursor: "pointer",
                                    color: "#49c0a0",
                                  }}
                                  onClick={isCustomDurationHandler}
                                >
                                  Click here to select Duration.
                                </div>
                              ) : (
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <TimeInput
                                      id="startTime"
                                      timeStampHandler={(secs) =>
                                        startTimeStampHandler(secs)
                                      }
                                      startTyping={startTypingTime}
                                      isValid={state.timeInputMsg}
                                    />
                                    to
                                    <TimeInput
                                      id="endTime"
                                      timeStampHandler={(secs) =>
                                        endTimeStampHandler(secs)
                                      }
                                      startTyping={startTypingTime}
                                      isValid={state.timeInputMsg}
                                    />
                                    <span
                                      style={{
                                        fontWeight: "700",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleClearCustomDuration}
                                    >
                                      x
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      color: "#dc3545",
                                      fontWeight: "500",
                                      marginTop: "6px",
                                      fontSize: "13px",
                                    }}
                                    className="text-left"
                                  >
                                    {state.timeInputMsg}
                                  </div>
                                </div>
                              )}
                              {/* <span
                          style={{
                            height: "1.2rem",
                            position: "absolute",
                            right: "-4rem",
                            top: "0.2rem",
                          }}
                          className="badge badge-info"
                        >
                          BETA
                        </span> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {uploadingType === "google-drive" && (
                    <div
                      className="m-auto text-center video--content-font"
                      style={{ width: "70%" }}
                    >
                      <label htmlFor=""> Enter a GDrive URL </label>
                      {/* <p className="finger-pointing-down"></p> */}

                      <div className="videoImg-content">
                        <img
                          src={`${process.env.REACT_APP_PROJECT_URL}/img/videoURL.svg`}
                          alt="videoImage"
                        />
                        <input
                          style={{
                            // cursor: "not-allowed",
                            opacity: "1",
                          }}
                          type="text"
                          className={`voideourl--text ${
                            !_.isEmpty(props.user) &&
                            !props.user.plan_details.video_is_allowed
                              ? ""
                              : ""
                          }`}
                          name="videourl"
                          data-tip
                          data-for="upload_video"
                          value={state.driveVideo}
                          onChange={driveFunction}
                          placeholder="Paste Video URL from Gdrive"
                          // disabled
                          onClick={() =>
                            setState({
                              activity_count: state.activity_count + 1,
                            })
                          }
                        />
                        {/* <Tooltip
                        id="upload_video"
                        title="You can either use YouTube URL or Upload video. This feature is currently disabled."
                      /> */}
                      </div>

                      {state.driveVideo && (
                        <div
                          style={{
                            display: "inline-flex",
                            gap: "10px",
                            alignItems: "center",
                            position: "relative",
                            marginTop: "1rem",
                          }}
                        >
                          {!state.isCustomDuration ? (
                            <div
                              style={{
                                cursor: "pointer",
                                color: "#49c0a0",
                              }}
                              onClick={isCustomDurationHandler}
                            >
                              Click here to select Duration.
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <TimeInput
                                  id="startTime"
                                  timeStampHandler={(secs) =>
                                    startTimeStampHandler(secs)
                                  }
                                  startTyping={startTypingTime}
                                  isValid={state.timeInputMsg}
                                />
                                to
                                <TimeInput
                                  id="endTime"
                                  timeStampHandler={(secs) =>
                                    endTimeStampHandler(secs)
                                  }
                                  startTyping={startTypingTime}
                                  isValid={state.timeInputMsg}
                                />
                                <span
                                  style={{
                                    fontWeight: "700",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleClearCustomDuration}
                                >
                                  x
                                </span>
                              </div>
                              <div
                                style={{
                                  color: "#dc3545",
                                  fontWeight: "500",
                                  marginTop: "6px",
                                  fontSize: "13px",
                                }}
                                className="text-left"
                              >
                                {state.timeInputMsg}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {/* Video Content Goes Here */}
              {state.currentActiveTab === 3 && (
                <CustomQuestions isblooms={is_bloom} />
              )}

              <div className="btn-flex100 video-section">
                {/*<button
                type="button"
                className="btn--submit"
                onClick={handleGenerateQuestionFromVideo}
                disabled={
                  state.videourl || state.isUploaded
                    ? false
                    : true
                }
              >
                Extract Content
              </button> */}

                {/* Filter Section */}
                {/* <Filter/> */}
                {videoExtractButtonContent()}
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                state.defaultActiveTab == "wiki" ? "active show" : ""
              }`}
              id="wiki"
              role="tabpanel"
              aria-labelledby="wiki-tab"
            >
              <div className="form-group custom-login-field">
                <div className="position-relative" style={{ height: "400px" }}>
                  {!is_bloom && (
                    <>
                      <label
                        htmlFor="searchTopic"
                        className={classNames(
                          styles["question-generate-tab-label"],
                          "custom-login-field--label"
                        )}
                      >
                        Search Your Topic Here
                      </label>
                      <Select
                        name="searchTopic"
                        className={classNames(
                          styles["question-generate-tab-input"]
                        )}
                        id="searchTopic"
                        onInputChange={onInputChange}
                        onChange={(option) =>
                          handleWikiTopicSelect("searchTopic", option)
                        }
                        onFocus={handleWikiTopicFocus}
                        onBlur={handleWikiTopicBlur}
                        onClick={handleWikiTopicFocus}
                        value={state.tmpselectedWikiTopic}
                        options={state.options}
                        placeholder={placeholders.searchTopic}
                      />
                    </>
                  )}{" "}
                  <div style={{ display: "flex" }}>
                    {is_bloom && (
                      <>
                        <input
                          className={classNames(
                            styles["question-generate-tab-input"],
                            "custom-login-field--inputs form-control mt-3 px-2 resize--none"
                          )}
                          name="searchTopic"
                          id="searchTopic"
                          onChange={onInputChange}
                          onKeyDown={handleEnter}
                          // onChange={(option) =>
                          //   handleWikiTopicSelect("searchTopic", option)
                          // }
                          // onFocus={handleWikiTopicFocus}
                          // onBlur={handleWikiTopicBlur}
                          // onClick={handleWikiTopicFocus}
                          value={state.tmpselectedWikiTopic}
                          // options={state.options}
                          placeholder={placeholders.searchTopic}
                          style={{ padding: "5px" }}
                        />
                        <i
                          className="fa fa-search search-hots"
                          aria-hidden="true"
                          onClick={getContent}
                        ></i>
                      </>
                    )}
                  </div>
                  <textarea
                    className={classNames(
                      styles["question-generate-tab-input"],
                      "custom-login-field--inputs form-control mt-3 px-2 resize--none"
                    )}
                    id="wikiContent"
                    name="wikiContent"
                    aria-describedby="textareaHelp"
                    onChange={handleChange}
                    rows="9"
                    value={state.wikiContent}
                    // disabled={state.searchTopic ? false : true}
                    placeholder={placeholders.wiki}
                    onClick={() =>
                      setState({
                        activity_count: state.activity_count + 1,
                      })
                    }
                    style={{ height: "74%" }}
                  ></textarea>
                </div>

                {state.currentActiveTab === 1 && (
                  <CustomQuestions isblooms={is_bloom} />
                )}

                <div className="btn-flex100">
                  {/* Filter Section */}
                  {/* <Filter/> */}
                  {/* {showSelectedFilter()} */}
                  <button
                    type="button"
                    className={classNames(
                      styles["question-generate-submit"],
                      "btn--submit"
                    )}
                    id="wiki-submit-button"
                    onClick={
                      props.user.credits <= 1 &&
                      !props.user.plan_details.is_new_pricing &&
                      is_bloom
                        ? redirectToPlansPage
                        : handleGenerateQuestionForWiki
                    }
                    disabled={
                      state.wikiContent
                        ? false
                        : props?.user?.plan_details?.is_new_pricing
                        ? true
                        : false
                    }
                  >
                    {props.user.credits <= 1 &&
                    !props.user.plan_details.is_new_pricing &&
                    is_bloom
                      ? "Buy Credits"
                      : "Generate Questions"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GenerateQuestionStdHots;
