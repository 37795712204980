import React from "react";
import style from "../cssmodule/bannner.module.css";
import { Link } from "react-router-dom/cjs/react-router-dom";
const BannerQuiz = () => {
  return (
    <div className={style.design}>
      <Link className={style.design} to="/quiz-multiplier">
        <img
          src="/img/banner_on.png"
          alt="added"
          width="100%"
          height="100%"
          style={{ objectFit: "cover" }}
        />
      </Link>
    </div>
  );
};

export default BannerQuiz;
